import '../../app.css';
import './connect-wallet.css';
import { useWallet } from '../../contexts/wallet-context';

export default function ConnectWallet() {
  const { address, connect } = useWallet();

  if (address) {
    return (
      <div className="wallet-address">{address}</div>
    )
  }

  return (
    <button className="metamask-button" onClick={connect}>
      Connect Wallet
    </button>
  );}