import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { ethers } from 'ethers';

import { Api } from '../providers/api';
import AeraV2Vault from '../contracts/aera-v2-vault.json';
import { useWallet } from './wallet-context';

const VaultsContext = createContext();

export const VaultsProvider = ({ children }) => {
  const [vaultStatus, setVaultStatus] = useState('update');
  const [contracts, setContracts] = useState([]);
  const [vaults, setVaults] = useState([]);

  const { chainId, provider } = useWallet();

  const fetchData = useCallback(() => {
    setVaultStatus(null);
    Api.getVaults()
      .then((data) => setVaults(data))
  }, []);

  useEffect(() => {
    if (vaultStatus !== 'update') return;
    fetchData();
  }, [fetchData, vaultStatus]);

  useEffect(() => {
    setContracts(
      vaults
        .filter(v => v.chainId === chainId)
        .map(v => new ethers.Contract(v.address, AeraV2Vault, new ethers.providers.Web3Provider(provider)))
    );
  }, [provider, chainId, vaults])

  return (
    <VaultsContext.Provider value={{ vaults, contracts, setVaultStatus }}>
      {children}
    </VaultsContext.Provider>
  );
};

export const useVaults = () => useContext(VaultsContext);
