import './data-table.css';

export function DataTable({ data }) {
  if (data.length === 0) {
    return (
      <p>No data</p>
    );
  }
  return (
    <table className="data-table">
      <thead>
      <tr>
        {Object.keys(data[0]).map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
      </thead>
      <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          {Object.values(row).map((value, i) => (
            <td key={i}>{value}</td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  );
}