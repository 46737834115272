export const chains = {
  '0x1': 'Ethereum',
  '0x89': 'Polygon',
  '0x2105': 'Base',
  '0xa4b1': 'Arbitrum',
}

export const chainIds = {
  ethereum: '0x1',
  polygon: '0x89',
  base: '0x2105',
  arbitrum: '0xa4b1',
}

export const explorerUrls = {
  ethereum: 'https://etherscan.io',
  polygon: 'https://polygonscan.com',
  base: 'https://basescan.org',
  arbitrum: 'https://arbiscan.io',
}
