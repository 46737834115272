import './tabs.css';
import { useState } from 'react';
import { DataTable } from '../data-table/data-table';

export function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`tab${activeTab === tab.label ? ' active' : ''}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabs.map(
          (tab) =>
            activeTab === tab.label && (
              <div key={tab.label} className="tab-pane">
                <DataTable data={tab.content} />
              </div>
            )
        )}
      </div>
    </div>
  );
}