import { EthereumLogo } from '../svg/ethereum-logo';
import { PolygonLogo } from '../svg/polygon-logo';
import { BaseLogo } from '../svg/base-logo';
import { ArbitrumLogo } from '../svg/arbitrum-logo';

export function NetworkLogo({ network }) {
  switch (network.toLowerCase()) {
    case 'ethereum':
      return (<EthereumLogo />);
    case 'polygon':
      return (<PolygonLogo />);
    case 'base':
      return (<BaseLogo />);
    case 'arbitrum':
      return (<ArbitrumLogo />);
    default:
      return null;
  }
}