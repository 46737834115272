import './loading-animation.css';

export function LoadingAnimation() {
  return (
    <div className="pong-animation-container">
      <svg width="150" height="100" viewBox="0 0 150 100">
        <circle cx="75" cy="50" r="10" className="ball"/>
      </svg>
    </div>
  );
}