import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import { WalletProvider } from './contexts/wallet-context';
import { VaultsProvider } from './contexts/vaults-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WalletProvider>
      <VaultsProvider>
        <App />
      </VaultsProvider>
    </WalletProvider>
  </React.StrictMode>
);
