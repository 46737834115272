import './network-selector.css';
import { useWallet } from '../../contexts/wallet-context';
import { chains } from '../../utils/constants';

export default function NetworkSelector() {
  const { chainId, switchChain } = useWallet();

  const handleChange = (event) => {
    const selectedNetwork = event.target.value;
    switchChain(selectedNetwork);
  };

  return (
    <div className="network-selector">
      <select onChange={handleChange} value={chainId}>
        {
          Object.keys(chains).map((key) => (
            <option value={key} key={key}>{chains[key]}</option>
          ))
        }
      </select>
    </div>
  );
};