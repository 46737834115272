import { useEffect, useState } from 'react';
import { explorerUrls } from '../../utils/constants';
import { LoadingAnimation } from '../loading-animation/loading-animation';
import { ExternalLinkIcon } from '../svg/external-link-icon';
import { Tabs } from '../tabs/tabs';
import { Api } from '../../providers/api';
import {
  formatAddress,
  formatDeposit,
  formatExecution,
  formatSubmission,
  formatTransfer,
  formatWithdrawal
} from '../../utils/format';

export function VaultDetails({ vault }) {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    Api.getVault(vault.network, vault.address)
      .then((details) => {
        console.log(details);
        setDetails([
          {
            label: 'Deposits',
            content: details.deposits.map(formatDeposit),
          },
          {
            label: 'Withdrawals',
            content: details.withdrawals.map(formatWithdrawal),
          },
          {
            label: 'Executions',
            content: details.executions.map(formatExecution),
          },
          {
            label: 'Submissions',
            content: details.submissions.map(formatSubmission),
          },
          {
            label: 'Transfers',
            content: details.transfers.map((transfer) => formatTransfer(vault.address, transfer)),
          },
        ]);
      })
  }, [vault, setDetails]);

  function getExplorerLink(vault, key) {
    const network = vault.network.toLowerCase();
    if (!explorerUrls[network]) {
      return '#';
    }
    return `${explorerUrls[network]}/address/${vault[key]}`;
  }

  if (!vault) {
    return null;
  }

  return (
    <>
      <h3>{formatAddress(vault.address)}</h3>
      <div>Network: {vault.network}</div>
      <div>Address: <a href={getExplorerLink(vault, 'address')} target="_blank" rel="noreferrer">
        {formatAddress(vault.address)}
        <ExternalLinkIcon />
      </a></div>
      <div>Owner: <a href={getExplorerLink(vault, 'owner')} target="_blank" rel="noreferrer">
        {formatAddress(vault.owner)}
        <ExternalLinkIcon />
      </a></div>
      <div>Guardian: <a href={getExplorerLink(vault, 'guardian')} target="_blank" rel="noreferrer">
        {formatAddress(vault.guardian)}
        <ExternalLinkIcon />
      </a></div>
      <div className="vault-details">
        {details === null ? (
          <LoadingAnimation />
        ) : (
          <Tabs tabs={details} />
        )}
      </div>
    </>
  )
}