import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletStatus, setWalletStatus] = useState(null);
  const [address, setAddress] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [shortAddress, setShortAddress] = useState(null);

  const provider = window.ethereum;

  window.addEventListener('load', () => setWalletStatus('update'));

  provider?.on('accountsChanged', () => setWalletStatus('update'));
  provider?.on('chainChanged', () => setWalletStatus('update'));
  const getWallet = useCallback(() => {
    if (!provider) {
      setAddress(null);
      setChainId(null);
      return;
    }
    setWalletStatus(null);
    setAddress(provider.selectedAddress);
    setChainId(provider.chainId);
  }, [provider]);

  useEffect(() => {
    if (walletStatus !== 'update') return;
    getWallet();
  }, [getWallet, walletStatus]);

  useEffect(() => {
    if (address === null) {
      setShortAddress(null);
      return;
    }
    setShortAddress([address.slice(0, 5), address.slice(-3)].join('...'));
  }, [address]);

  const connect = () => {
    return provider?.request({ method: 'eth_requestAccounts', params: [] });
  }

  const switchChain = (id) => {
    if (id === chainId) return;
    return provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{
        chainId: id
      }]
    })
  }

  return (
    <WalletContext.Provider value={{ address, shortAddress, chainId, setWalletStatus, connect, switchChain, provider }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
