import './vaults.css';
import { NetworkLogo } from '../network-logo/network-logo';

export default function Vault({ vault, onClick }) {
  return (
    <div className="vault" onClick={onClick}>
      <div className="network-logo">
        <NetworkLogo network={vault.network} />
      </div>
      <p>{vault.address}</p>
    </div>
  );
}