import { BigNumber } from 'ethers';

export function formatDeposit(deposit) {
  const amount = BigNumber.from(deposit.amount);
  const expDiv = BigNumber.from(10).pow(Math.max(1, deposit.decimals - 3));
  return {
    Token: deposit.asset,
    Amount: amount.div(expDiv).toNumber() / 1000,
    Owner: formatAddress(deposit.owner),
  }
}

export const formatWithdrawal = formatDeposit;

export function formatExecution(execution) {
  console.log(execution);
  const amount = BigNumber.from(execution.value);
  const expDiv = BigNumber.from(10).pow(16);
  return {
    Owner: formatAddress(execution.owner),
    Target: formatAddress(execution.target),
    Value: amount.div(expDiv).toNumber() / 1000,
    Data: '0x...',
  }
}

export function formatSubmission(submission) {
  return {
    Guardian: formatAddress(submission.guardian),
    Operations: submission.operationCount,
  }
}

export function formatTransfer(vaultAddress, transfer) {
  const amount = BigNumber.from(transfer.amount);
  const expDiv = BigNumber.from(10).pow(Math.max(1, transfer.decimals - 3));
  const direction = ((vault, from, to) => {
    if (vault === from) return 'Sent';
    if (vault === to) return 'Received';
    return '???';
  })(vaultAddress, transfer.from, transfer.to);
  return {
    Token: transfer.token,
    Direction: direction,
    Amount: amount.div(expDiv).toNumber() / 1000,
  }
}

export function formatAddress(address) {
  console.log('Format Address', address);
  return [address.slice(0, 6), address.slice(-4)].join('...');
}

