export function EthereumLogo() {
  return (
    <svg version="1.0" viewBox="0 0 732 732">
	    <circle fill="#627EEA" cx="366" cy="366" r="365.8"/>
      <polygon fill="#FFFFFF" fillOpacity="0.602" points="366.2,107.1 366.2,309.9 537.6,386.5"/>
      <polygon fill="#FFFFFF" points="366.2,107.1 194.8,386.5 366.2,309.9"/>
      <polygon fill="#FFFFFF" fillOpacity="0.602" points="366.2,517.9 366.2,655.7 537.7,418.4"/>
      <polygon fill="#FFFFFF" points="366.2,655.7 366.2,517.9 194.8,418.4"/>
      <polygon fill="#FFFFFF" fillOpacity="0.2" points="366.2,486 537.6,386.5 366.2,309.9"/>
      <polygon fill="#FFFFFF" fillOpacity="0.602" points="194.8,386.5 366.2,486 366.2,309.9"/>
    </svg>
  )
}