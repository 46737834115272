const BASE_URL = process.env.REACT_APP_BASE_URL;

export class HttpError extends Error {
  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

async function get(url) {
  const headers = {
    'content-type': 'application/json',
  };
  const res = await fetch(`${BASE_URL}${url}`, {
    headers,
  });
  const json = await res.json();
  if (res.status >= 400) {
    throw new HttpError(res.status, json.error);
  }
  return json;
}

export class Api {
  static getVaults() {
    return get('/vaults');
  }

  static getVault(network, address) {
    return get(`/vaults/${network}/${address}`);
  }
}
