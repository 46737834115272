import { useVaults } from '../../contexts/vaults-context';
import Vault from './vault';
import Modal from '../modal/modal';
import { useState } from 'react';
import { VaultDetails } from './vault-details';

export default function Vaults() {
  const [selectedVault, setSelectedVault] = useState(null);

  const { vaults } = useVaults();

  if (!vaults) return (<div>Loading...</div>);
  return (
    <>
      <Modal isOpen={selectedVault !== null} onClose={() => setSelectedVault(null)}>
        <VaultDetails vault={selectedVault} />
      </Modal>
      <h1>Aera Vaults</h1>
      <div className="vaults">
        {
          vaults.map(((vault, index) => (
            <Vault vault={vault} key={index} onClick={() => setSelectedVault(vault)} />
          )))
        }
      </div>
    </>
  );
}