import './header.css';
import logo from '../../logo-full.svg';
import ConnectWallet from '../connect-wallet/connect-wallet';
import NetworkSelector from '../network-selector/network-selector';
import { useWallet } from '../../contexts/wallet-context';

export default function Header() {
  const { shortAddress } = useWallet();

  return (
    <header className="app-header">
      <img height={80} src={logo} className="app-logo" alt="logo" />
      <div className="wallet-section">
        {
          !shortAddress ? (<ConnectWallet/>) : (
            <>
              <NetworkSelector/>
              <div className="wallet-address">{shortAddress}</div>
            </>
          )
        }
      </div>
    </header>
  )
}