import './app.css';
import Header from './components/header/header';
import Vaults from './components/vaults/vaults';

export default function App() {
  return (
    <div className="app">
      <Header/>
      <div className="main-content">
        <Vaults />
      </div>
    </div>
  )
}
